import React, { Suspense, lazy, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, Routes } from "react-router-dom";

import { Header, Footer, Loader } from "../common/utils/Main";

import { checkIn, checkInVisitor } from "../../redux/actions/account";
import { fetchCart } from "../../redux/actions/cart";
import { fetchPage } from "../../redux/actions/page";
import { fetchProducts } from "../../redux/actions/products";
import { fetchWishlist } from "../../redux/actions/wishlist";
import { fetchCategories } from "../../redux/actions/categories";
import PromoBanner from "../common/Landing/PromoBanner";
import { LazyLoad } from "../common/utils/Lazyload";

const About = lazy(() => delayForClient(import("./About")));
const Account = lazy(() => delayForClient(import("./Account")));
const Checkout = lazy(() => delayForClient(import("./Checkout")));
const Contact = lazy(() => delayForClient(import("./Contact")));
const Edit = lazy(() => delayForClient(import("./Edit")));
const Home = lazy(() => delayForClient(import("./Home")));
const Insert = lazy(() => delayForClient(import("./Insert")));
const Login = lazy(() => delayForClient(import("./Login")));
const Promocodes = lazy(() => delayForClient(import("./Promocodes")));
const Order = lazy(() => delayForClient(import("./Order")));
const Orders = lazy(() => delayForClient(import("./Orders")));
const Privacy = lazy(() => delayForClient(import("./Privacy")));
const Product = lazy(() => delayForClient(import("./Product")));
const Products = lazy(() => delayForClient(import("./Products")));
const Register = lazy(() => delayForClient(import("./Register")));
const Return = lazy(() => delayForClient(import("./Return")));
const TOS = lazy(() => delayForClient(import("./TOS")));
const Shipping = lazy(() => delayForClient(import("./Shipping")));
const GDPR = lazy(() => delayForClient(import("./GDPR")));
const Cart = lazy(() => delayForClient(import("./Cart")));
const Wishlist = lazy(() => delayForClient(import("./Wishlist")));

function delayForClient(promise) {
    return new Promise(resolve => {
        setTimeout(resolve, 2500);
    }).then(() => promise);
}


const Application = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (localStorage.getItem("token")!= null && localStorage.getItem("visitor")!= "true") {
            dispatch(checkIn());
            dispatch(fetchCart());
            dispatch(fetchWishlist());
        }
        else {
            dispatch(checkInVisitor());
        }

        dispatch(fetchProducts());
        dispatch(fetchPage());
        dispatch(fetchCategories());
    }, []);

    return (
        <Suspense fallback={<Loader />}>
            <PromoBanner parameter={"landing_promo_banner"} />
            <Header />

            <Routes>
                <Route exact path="/about" element={<About />} />
                <Route exact path="/account" element={<Account />} />
                <Route exact path="/contact" element={<Contact />} />
                <Route exact path="/cart" element={<Cart />} />
                <Route exact path="/edit" element={<Edit />} />
                <Route exact path="/privacy" element={<Privacy />} />
                <Route exact path="/tos" element={<TOS />} />
                <Route exact path="/gdpr" element={<GDPR />} />
                <Route exact path="/login" element={<Login />} />
                <Route exact path="/register" element={<Register />} />
                <Route exact path="/return" element={<Return />} />
                <Route exact path="/shipping" element={<Shipping />} />
                <Route exact path="/insert" element={<Insert />} />
                <Route exact path="/promocodes" element={ <Promocodes /> } />
                <Route exact path="/products" element={<Products />} />
                <Route exact path="/product" element={<Product />} />
                <Route exact path="/order" element={<Order />} />
                <Route exact path="/order/:id" element={<Order />} />
                <Route exact path="/orders" element={<Orders />} />
                <Route exact path="/checkout" element={<Checkout />} />
                <Route exact path="/wishlist" element={<Wishlist />} />
                <Route exact path="/" element={<Home />} />
            </Routes>
            
            <LazyLoad>
            <Footer />
            </LazyLoad>
        </Suspense>
    );
};

export default Application;
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { editPage, fetchPage } from "../../../redux/actions/page";
import { Card } from "../Product";
import { Link } from "react-router-dom";
import { LazyLoad } from "../utils/Lazyload";

const Products = ({ title, filter, category }) => {
    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    const dispatch = useDispatch();

    const account = useSelector(state => state.account.data);
    const products = useSelector(state => state.products.data);
    const categories = useSelector(state => state.categories.data);
    const page = useSelector(state => state.page);

    const getPageParameter = (parameter) => {
        for (const param of page?.data) {
            if (param.name == parameter) {
                return param.type == "integer" ? Number(param.value) : param.value;
            }
        }

        return null;
    }

    const filters = [
        { value: 'none', label: 'Niciuna' },
        { value: 'promo', label: 'Promotii' },
        { value: 'new', label: 'Produse noi' },
        { value: 'upprice', label: 'Pret crescator' },
        { value: 'downprice', label: 'Pret descrescator' },
        { value: 'stock', label: 'Stoc disponibil' }
    ];

    const [titleState, setTitleState] = useState("title");
    const [filterState, setFilterState] = useState("filter");
    const [categoryState, setCategoryState] = useState("category");

    const [editMode, setEditMode] = useState(false);

    useEffect(() => {
        setTitleState(getPageParameter(title));
        setFilterState(getPageParameter(filter));
        setCategoryState(getPageParameter(category));
    }, [page]);

    return (
        <div className={isMobile ? "d-flex flex-column input__group m-auto w-100" : "d-flex flex-column input__group m-auto p-0 fitswell"}>
            {
                editMode ?
                    <>
                        <input className="title text-center" value={titleState} onChange={e => setTitleState(e.target.value)} />

                        <div className="d-flex flex-row justify-content-center gap-3">
                            <div>
                                <label>Categorie:</label>
                                <select
                                    value={categoryState}
                                    onChange={(e) => setCategoryState(e.target.value)}
                                >
                                    <option value="0">Din toate</option>
                                    {
                                        categories?.filter(category => category.type == "primary" || category.type == "child")?.map((value, idx) => (
                                            <option key={idx} value={value.id}>{value.title}</option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div>
                                <label>Filtru:</label>
                                <select
                                    value={filterState}
                                    onChange={(e) => setFilterState(e.target.value)}
                                >
                                    {
                                        filters?.map((value, idx) => (
                                            <option key={idx} value={value.value}>{value.label}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <h1 className="title text-start fs-4 text-pink bold">{titleState}</h1>
                        <div className={isMobile ? "products flex-wrap mx-3" : "products flex-nowrap mx-3"}>
                            {
                                products?.sort((elm1, elm2) => {
                                    switch (filterState) {
                                        case 'upprice':
                                            return (elm1.price - elm2.price);
                                        case 'downprice':
                                            return (elm2.price - elm1.price);
                                        case 'stock':
                                            return (elm2.stock - elm1.stock);
                                        case 'new':
                                            return (elm2.id - elm1.id);
                                        case 'promo':
                                            return (JSON.parse(elm2.content).promo - JSON.parse(elm1.content).promo);
                                        default:
                                            return true
                                    }
                                }).filter((elm) => elm.categories.find(value => value == categoryState || categoryState == "none" || categoryState == 0) && elm.stock > 0).slice(0, 4).map(
                                    (value, idx) => (
                                        <Card key={idx} landing={true} product={{ ...value, images: JSON.parse(value.images), content: JSON.parse(value.content) }} />
                                    )
                                )
                            }
                        </div>
                    </>
            }

            {
                account.type == "master" &&
                (
                    editMode ?
                        <div className="d-flex flex-row m-auto">
                            <div className="m-4 user-select-none">
                                <span className="material-icons-outlined pointer" onClick={() => {
                                    setEditMode(!editMode);

                                    dispatch(editPage({
                                        name: title,
                                        value: titleState
                                    }))

                                    dispatch(editPage({
                                        name: category,
                                        value: categoryState
                                    }))

                                    dispatch(editPage({
                                        name: filter,
                                        value: filterState
                                    }))
                                    .then(() => dispatch(fetchPage()))
                                }}>done</span>
                            </div>
                            <div className="m-4 user-select-none">
                                <span className="material-icons-outlined pointer" onClick={() => {
                                    setEditMode(!editMode);
                                    setTitleState(getPageParameter(title));
                                    setFilterState(getPageParameter(filter));
                                    setCategoryState(getPageParameter(category));
                                }}>close</span>
                            </div>
                        </div>
                        :
                        <div className="m-4 mx-auto user-select-none">
                            <span className="material-icons-outlined pointer" onClick={() => setEditMode(!editMode)}>edit</span>
                        </div>
                )
            }
        </div>
    )
}

export default Products;
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import { Menu } from "../Navbar";
import { Image } from "../Lazyload";
import { setFilterCategories, setFilterType } from "../../../../redux/actions/filters";

const Header = () => {
  const dispatch = useDispatch();

  const categories = useSelector((state) => state.categories.data);
  const cart = useSelector((state) => state.cart.data);
  const wishlist = useSelector((state) => state.wishlist.data);
  const user = useSelector((state) => state.account.data);

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 1440;
  const [showMenu, setShowMenu] = useState(false);
  const [showCart, setShowCart] = useState(false);
  const [showWishlist, setShowWishlist] = useState(false);

  const handleMenu = (e) => {
    e?.preventDefault();
    setShowMenu(!showMenu);
  };

  const handleCart = (e) => {
    e?.preventDefault();
    setShowCart(!showCart);
  };

  const handleWishlist = (e) => {
    e?.preventDefault();
    setShowWishlist(!showWishlist);
  };

  const getCartLength = () => {
    let length = 0;

    cart?.length > 0 &&
      cart?.map((item) => {
        length += item.amount;
      });
    return length;
  };

  return (
    <header>
      <div className="d-flex flex-row justify-content-between">
        {!isMobile ? (
          <>
            <div className="d-flex flex-row justify-content-between w-100 mx-5">
              <div className="nav my-auto">
                <div className="nav__option">
                  Catalogul nostru <div className="arrow__down"></div>
                  <div className="nav__dropdown">
                      <Link
                        to={`/products`}
                        className="choice"
                        onClick={() => dispatch(setFilterCategories([]))}
                      >
                        <span className="icon material-icons-outlined">keyboard_arrow_right</span> Toate produsele
                      </Link>
                      <Link
                        to={`/products?categories=clearance`}
                        className="choice"
                        onClick={() => dispatch(setFilterCategories([categories.find((value) => value.hotkey == "clearance")]))}
                      >
                        <span className="icon material-icons-outlined">keyboard_arrow_right</span> Lichidari de stoc
                      </Link>
                      <Link
                        to={`/products?categories=season`}
                        className="choice"
                        onClick={() => dispatch(setFilterCategories([categories.find((value) => value.hotkey == "season")]))}
                      >
                        <span className="icon material-icons-outlined">keyboard_arrow_right</span> Promotii de sezon
                      </Link>
                    {categories
                      .filter((value) => value.type=="primary")
                      .slice(0, 4)
                      .map((category, index) => (
                        <Link
                          key={index}
                          to={`/products?categories=${category.hotkey}`}
                          className="choice"
                          onClick={() => dispatch(setFilterCategories([category]))}
                        >
                          <span className="icon material-icons-outlined">keyboard_arrow_right</span> {category.title}
                        </Link>
                      ))}
                  </div>
                </div>
                <Link className="nav__option" to="/contact">
                  Contacteaza-ne
                </Link>
                <Link className="nav__option" to="/about">
                  Despre noi
                </Link>
              </div>

              <Link to="/">
                <Image
                  className="logo"
                  src={require("../../../../assets/logo.svg").default}
                  alt="Logo."
                />
              </Link>

              <div className="my-auto d-flex flex-row justify-content-center gap-4">
                <Link
                  className="nav__button"
                  to={
                    JSON.stringify(user?.data) != "{}" ? "/account" : "/login"
                  }
                >
                  <Image
                    className="nav__button__img"
                    src={require("../../../../assets/account.svg").default}
                    alt="My account."
                  />
                </Link>

                <Link className="nav__button" to="/cart">
                  <Image
                    className="nav__button__img"
                    src={require("../../../../assets/cart.svg").default}
                    alt="My shopping cart."
                  />
                  <div className="nav__button__count">
                    {getCartLength()}
                  </div>
                </Link>
                <Link className="nav__button" to="/wishlist">
                  <Image
                    className="nav__button__img"
                    src={require("../../../../assets/wishlist.svg").default}
                    alt="Wishlist."
                  />
                  <div className="nav__button__count">
                    {Object.keys(wishlist)?.length}
                  </div>
                </Link>
              </div>
            </div>
          </>
        ) : (
          <div className="d-flex flex-row justify-content-between align-items-center w-100">
            <Menu show={showMenu} onClose={handleMenu} />

            <div className="nav__button p-1 w-25" onClick={handleMenu}>
              <Image
                className="nav__button__img"
                src={require("../../../../assets/menu.svg").default}
                alt="My menu."
              />
            </div>

            <Link to="/" className="d-flex flex-row justify-content-center align-items-center py-2 gap-1">
              <Image
                className="logo"
                src={require("../../../../assets/logo.svg").default}
                alt="Logo."
              />
            </Link>

            <div className="d-flex flex-row justify-content-end gap-2 w-25">
              <Link className="nav__button p-1" to="/cart">
                <Image
                  className="nav__button__img"
                  src={require("../../../../assets/cart.svg").default}
                  alt="My shopping cart."
                />
                <div className="nav__button__count">
                  {getCartLength()}
                </div>
              </Link>

              <Link className="nav__button p-1" to="/wishlist">
                <Image
                  className="nav__button__img"
                  src={require("../../../../assets/wishlist.svg").default}
                  alt="Wishlist."
                />
                <div className="nav__button__count">
                  {Object.keys(wishlist)?.length}
                </div>
              </Link>
            </div>
          </div>
        )}
      </div>
    </header >
  );
};

export default Header;
import {
    PRODUCTS_STARTING,
    PRODUCTS_SUCCESSFULL,
    PRODUCTS_FAILED
} from '../actions/products';

import updateObject from "../updateObject";

const initialState = {
    inLoading: false,
    error: "",
    filter: "none",
    category: 0,
    data: []
};

export function productsStart(state, action) {
    return updateObject(state, {
        inLoading: true
    });
}

export function productsSuccess(state, action) {
    return updateObject(state, {
        inLoading: false,
        data: action.payload
    });
}

export function productsFailed(state, action) {
    return updateObject(state, {
        inLoading: false,
        error: action.payload
    });
}

export function productsReducer(state = initialState, action) {
    switch (action.type) {
        case PRODUCTS_STARTING: return productsStart(state, action);
        case PRODUCTS_SUCCESSFULL: return productsSuccess(state, action);
        case PRODUCTS_FAILED: return productsFailed(state, action);

        default: return state;
    }
}
import axios from "axios";

export const ORDERS_STARTING = 'ORDERS_STARTING';
export const ORDERS_SUCCESSFULL = 'ORDERS_SUCCESSFULL';
export const ORDERS_FAILED = 'ORDERS_FAILED';

export function ordersStarting(payload) {
    return {
        type: ORDERS_STARTING,
        payload: payload
    };
}

export function ordersSuccessfull(payload) {
    return {
        type: ORDERS_SUCCESSFULL,
        payload: payload
    };
}

export function ordersFailed(payload) {
    return {
        type: ORDERS_FAILED,
        payload: payload
    };
}

export const fetchOrdersHistory = (props) => {
    return async (dispatch) => {
        dispatch(ordersStarting());
        
        axios.get(`${process.env.REACT_APP_API_KEY}/orders?token=${localStorage.getItem("token")}`)
        .then(res => {
            dispatch(ordersSuccessfull(res?.data));
        }).catch(err => {
            dispatch(ordersFailed(err.response?.data?.error?.message));
        });
    }
};

export const fetchOrder = (props) => {
    return async (dispatch) => {
        dispatch(ordersStarting());

        axios.get(`${process.env.REACT_APP_API_KEY}/orders?id=${props.id}`)
        .then(res => {
            dispatch(ordersSuccessfull(res?.data));
        }).catch(err => {
            dispatch(ordersFailed(err.response?.data?.error?.message));
        });
    }
};

export const fetchOrdersMaster = (props) => {
    return async (dispatch) => {
        dispatch(ordersStarting());
        
        axios.get(`${process.env.REACT_APP_API_KEY}/orders`)
        .then(res => {
            dispatch(ordersSuccessfull(res?.data));
        }).catch(err => {
            dispatch(ordersFailed(err.response?.data?.error?.message));
        });
    }
};

export const editOrder = (props) => {
    axios.put(`${process.env.REACT_APP_API_KEY}/orders`,
    {
        ...props
    },
    {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${localStorage.getItem("token")}`
        }
    })
    .then(res => {
        window.location.reload();
    }).catch(err => {});
};
import React, { useEffect, useState } from "react";
import { TextInput } from "../Editable";


const PromoBanner = ({ parameter }) => {
    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    return (
        <div className="promo__banner">
            <div className={isMobile ? "promo__banner__title w-100 text-center d-flex flex-row justify-content-center" : "promo__banner__title w-50 text-center d-flex flex-row justify-content-center"}>
                <TextInput parameter={parameter} />
            </div>
        </div>
    );
};

export default PromoBanner;
import { composeWithDevTools } from "redux-devtools-extension";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import { combineReducers } from 'redux';
import { accountReducer } from './reducers/account';
import { cartReducer } from './reducers/cart';
import { categoriesReducer } from './reducers/categories';
import { checkoutReducer } from './reducers/checkout';
import { cookiesReducer } from './reducers/cookies';
import { ordersReducer } from './reducers/orders';
import { pageReducer } from './reducers/page';
import { productsReducer } from './reducers/products';
import { productReducer } from './reducers/product';
import { promoReducer } from './reducers/promo';
import { reviewsReducer } from './reducers/reviews';
import { wishlistReducer } from './reducers/wishlist';
import { filtersReducer } from './reducers/filters';

const rootReducer = combineReducers({
    account: accountReducer,
    cart: cartReducer,
    categories: categoriesReducer,
    checkout: checkoutReducer,
    cookies: cookiesReducer,
    orders: ordersReducer,
    page: pageReducer,
    products: productsReducer,
    product: productReducer,
    promo: promoReducer,
    reviews: reviewsReducer,
    wishlist: wishlistReducer,
    filters: filtersReducer
});

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));

export default store;
import React from "react";
import { Link } from "react-router-dom";

import { Slider } from "../Product";

const Card = ({ product, landing }) => {
    return (
        <Link className={`product ${landing ? "product--landing" : ""}`} style={{
            "opacity": product.stock > 0 ? 1 : 0.5,
        }} to={`/product?id=${product?.id}`}>

            <Slider images={product?.images} settings={{ arrows: false }} promo={product.content?.promo} />

            <div className="menu">
                <div className="dots">
                    {
                        product?.images?.map((image, index) => (
                            <div key={index} className="dot"></div>
                        ))
                    }
                </div>
                <div className="title">
                    {product.title.length > 20 ? product.title.substring(0, 20) + "..." : product.title}
                </div>
                <div className="price">
                    {
                        product?.content?.promo > 0 ?
                            <>
                                <strike>{product.price.toLocaleString("ro-RO", { style: "currency", currency: "RON" }).replace('RON', 'lei')}</strike> <span className="promo"> - {product.content?.promo}%</span> {(Math.floor((product.price - product.price * product.content?.promo / 100) * 100) / 100).toLocaleString("ro-RO", { style: "currency", currency: "RON" }).replace('RON', 'lei')}
                            </>
                            :
                            <>
                                {product.price.toLocaleString("ro-RO", { style: "currency", currency: "RON" }).replace('RON', 'lei')}
                            </>
                    }
                </div>
                <div className="hide">
                    <div className="colors">
                        {
                            product?.content?.colors?.map((color, index) => (
                                <div key={index} className={`color ${color}`}></div>
                            ))
                        }
                    </div>
                    <div className="description">
                        Disponibil in mai multe marimi.
                    </div>
                </div>
            </div>
        </Link>
    )
};

export default Card;
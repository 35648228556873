import {
    SET_CART,
    ADD_ITEM_CART,
    REMOVE_ITEM_CART,
    REMOVE_ITEM_ALL_CART
} from '../actions/cart';

import updateObject from "../updateObject";

const initialState = {
    data: localStorage.getItem("cart") ? JSON.parse(localStorage.getItem("cart")) : []
};

export function set(state, action) {
    if (!(JSON.stringify(action.payload).includes("content") && JSON.stringify(action.payload).includes("amount"))) {
        localStorage.setItem("cart", JSON.stringify([]));
        window.dispatchEvent( new Event('storage') );
        
        return {data: []};
    }

    action.payload.map(product => {
        product.content = JSON.parse(product.content);
    });

    localStorage.setItem("cart", JSON.stringify(action.payload));
    window.dispatchEvent( new Event('storage') );
    return updateObject(state, {
        data: action.payload,
    });
}

export function addItem(state, action) {
    if (state.data.find(product => product.id==action.item && JSON.stringify(product.content)==JSON.stringify(action.content))==undefined) {
        state.data.push({id: action.item, amount: action.amount, content: action.content});
    }
    else {
        state.data.find(product => product.id==action.item && JSON.stringify(product.content)==JSON.stringify(action.content)).amount += action.amount;
    }

    localStorage.setItem("cart", JSON.stringify(state.data));
    window.dispatchEvent( new Event('storage') );
    return updateObject(state, {
        data: [...state.data],
    });
}

export function RemoveItem(state, action) {
    if (state.data.find(product => product.id==action.item && JSON.stringify(product.content)==JSON.stringify(action.content))!= undefined) {
        state.data.find(product => product.id==action.item && JSON.stringify(product.content)==JSON.stringify(action.content)).amount--;
        if (state.data.find(product => product.id==action.item && JSON.stringify(product.content)==JSON.stringify(action.content)).amount==0) {
            state.data= state.data.filter(product => product.id!= action.item && JSON.stringify(product.content)!= JSON.stringify(action.content));
        }
    }

    localStorage.setItem("cart", JSON.stringify(state.data));
    window.dispatchEvent( new Event('storage') );
    return updateObject(state, {
        data: [...state.data],
    });
}

export function RemoveAllItems(state, action) {
    localStorage.setItem("cart", JSON.stringify([]));
    window.dispatchEvent( new Event('storage') );
    return [];
}

export function cartReducer(state = initialState, action) {
    switch (action.type) {
        case SET_CART: return set(state, action);
        case ADD_ITEM_CART: return addItem(state, action);
        case REMOVE_ITEM_CART: return RemoveItem(state, action);
        case REMOVE_ITEM_ALL_CART: return RemoveAllItems(state, action);
        default: return state;
    }
}
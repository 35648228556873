import {
    ORDERS_STARTING,
    ORDERS_SUCCESSFULL,
    ORDERS_FAILED
} from '../actions/orders';

import updateObject from "../updateObject";

const initialState = {
    inLoading: false,
    error: "",
    data: []
};

export function ordersStart(state, action) {
    return updateObject(state, {
        inLoading: true
    });
}

export function ordersSuccess(state, action) {
    return updateObject(state, {
        inLoading: false,
        data: action.payload
    });
}

export function ordersFailed(state, action) {
    return updateObject(state, {
        inLoading: false,
        error: action.payload
    });
}

export function ordersReducer(state = initialState, action) {
    switch (action.type) {
        case ORDERS_STARTING: return ordersStart(state, action);
        case ORDERS_SUCCESSFULL: return ordersSuccess(state, action);
        case ORDERS_FAILED: return ordersFailed(state, action);

        default: return state;
    }
}
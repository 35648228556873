import {
    CHECKOUT_STARTING,
    CHECKOUT_SUCCESSFULL,
    CHECKOUT_FAILED
} from "../actions/checkout";

import updateObject from "../updateObject";

const initialState = {
    inLoading: false,
    error: "",
};

export function checkoutStart(state, action) {
    return updateObject(state, {
        inLoading: true
    });
}

export function checkoutSuccess(state, action) {
    return updateObject(state, {
        inLoading: false,
    });
}

export function checkoutFailed(state, action) {
    return updateObject(state, {
        inLoading: false,
        error: action.payload
    });
}

export function checkoutReducer(state = initialState, action) {
    switch (action.type) {
        case CHECKOUT_STARTING: return checkoutStart(state, action);
        case CHECKOUT_SUCCESSFULL: return checkoutSuccess(state, action);
        case CHECKOUT_FAILED: return checkoutFailed(state, action);

        default: return state;
    }
}
import React from "react";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from 'react-dom/client';
import reportWebVitals from "./reportWebVitals";
import Application from "./components/pages";

import { Provider } from "react-redux";
import store from './redux/store';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import "./sass/index.css";
import "../node_modules/slick-carousel/slick/slick.css";
import "../node_modules/slick-carousel/slick/slick-theme.css";

const container = document.getElementById('root');
const root = createRoot(container); 

root.render(
    <BrowserRouter>
        <Provider store={store}>
            <Application />
            <ToastContainer
                position = "bottom-right"
                hideProgressBar = {true}
                closeOnClick = {true}
                autoClose={5000}
                rtl = {false}
                theme = "light"
            />
        </Provider>
    </BrowserRouter>
);

reportWebVitals();

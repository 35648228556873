import axios from "axios";

export const CATEGORIES_STARTING = 'CATEGORIES_STARTING';
export const CATEGORIES_SUCCESSFULL = 'CATEGORIES_SUCCESSFULL';
export const CATEGORIES_FAILED = 'CATEGORIES_FAILED';

export function categoriesStarting(payload) {
    return {
        type: CATEGORIES_STARTING,
        payload: payload
    };
}

export function categoriesSuccessfull(payload) {
    return {
        type: CATEGORIES_SUCCESSFULL,
        payload: payload
    };
}

export function categoriesFailed(payload) {
    return {
        type: CATEGORIES_FAILED,
        payload: payload
    };
}

export const fetchCategories = (props) => {
    return async (dispatch) => {
        dispatch(categoriesStarting());

        axios.get(`${process.env.REACT_APP_API_KEY}/categories`)
        .then(res => {
            dispatch(categoriesSuccessfull(res.data));
        }).catch(err => {
            dispatch(categoriesFailed(err.response.data.error.message));
        });
    }
};
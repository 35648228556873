
import React, { useEffect, useState } from "react";
import TextInput from "./TextInput";
import { Image } from "../utils/Lazyload";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ImageUploader } from "../utils/Upload";
import { editPage } from "../../../redux/actions/page";
import axios from "axios";
import { setFilterCategories, setFilterType } from "../../../redux/actions/filters";

const Category = ({ index }) => {
    const dispatch = useDispatch();

    const categories = useSelector(state => state.categories.data);
    const account = useSelector(state => state.account.data);
    const page = useSelector(state => state.page);

    const getPageParameter = (parameter) => {
        for (const param of page?.data) {
            if (param.name == parameter) {
                return param.type == "integer" ? Number(param.value) : param.value;
            }
        }

        return null;
    }

    const [editMode, setEditMode] = useState(false);
    const [buttonCategory, setButtonCategory] = useState(0);
    const [buttonFilter, setButtonFilter] = useState(0);
    const [image, setImage] = useState("");

    useEffect(() => {
        if (page && page.data.length > 0) {
            setImage(getPageParameter(`landing_category_${index}_image`));
            setButtonCategory(getPageParameter(`landing_category_${index}_button_category`));
            setButtonFilter(getPageParameter(`landing_category_${index}_button_filter`));
        }
    }, [page])

    const uploadImage = async (image, cb) => {
        const formData = new FormData();
        formData.append("image", image);

        await axios.post(`${process.env.REACT_APP_API_KEY}/files/options`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    "Authorization": "Token " + localStorage.getItem("token")
                }
            })
            .then(res => {
                cb(res.data.link);
            }).catch(err => { });
    }

    const filters = [
        { value: 'none', label: 'Niciuna' },
        { value: 'promo', label: 'Promotii' },
        { value: 'new', label: 'Produse noi' },
        { value: 'upprice', label: 'Pret crescator' },
        { value: 'downprice', label: 'Pret descrescator' },
        { value: 'stock', label: 'Stoc disponibil' }
    ];

    return (
        <div className="category">
            <Image className="image" src={image} alt="Category image." />

            <div className="overlay">
                <h1 className="title fs-4 text-black">
                    <TextInput parameter={`landing_category_${index}_title`} />
                </h1>

                {
                    editMode ?
                        <>
                            <select className='w-50' defaultValue={buttonCategory} onChange={(e) => setButtonCategory(e.target.value)}>
                                <option value={0}>Toate produsele</option>
                                {
                                    categories?.filter(category => category.type == "primary" || category.type == "child")?.map((category, idx) => (
                                        <option key={idx} value={category.id}>{category.title}</option>
                                        ))
                                    }
                            </select>
                            <select className='w-50' defaultValue={buttonFilter} onChange={(e) => setButtonFilter(e.target.value)}>
                                {
                                    filters?.map((filter, idx) => (
                                        <option key={idx} value={filter.value}>{filter.label}</option>
                                    ))
                                }
                            </select>

                            <ImageUploader value={image} setImage={image => uploadImage(image, (image) => {
                                setImage(image);
                                dispatch(editPage({
                                    value: image,
                                    name: `landing_category_${index}_image`
                                }));
                            })} />
                            <div className="d-flex flex-row justify-content-center">
                                <span className="material-icons-outlined pointer text-white" onClick={async () => {
                                    setEditMode(!editMode);
                                    dispatch(editPage({
                                        value: buttonCategory,
                                        name: `landing_category_${index}_button_category`
                                    }));
                                    dispatch(editPage({
                                        value: buttonFilter,
                                        name: `landing_category_${index}_button_filter`
                                    }));
                                }
                                }>done</span>
                                <span className="material-icons-outlined pointer text-white" onClick={() => {
                                    setEditMode(!editMode);
                                    setButtonCategory(getPageParameter(`landing_category_${index}_button_category`));
                                    setButtonFilter(getPageParameter(`landing_category_${index}_button_filter`));
                                    setImage(getPageParameter(`landing_category_${index}_image`));
                                }}>close</span>
                            </div>
                        </>
                        :
                        <>
                            <Link className="button w-fit" to={`/products?categories=${categories?.find(category => category.id == buttonCategory)?.hotkey}`} onClick={() => {
                                dispatch(setFilterCategories([categories?.find(category => category.id == buttonCategory)]));
                                dispatch(setFilterType(buttonFilter));
                            }}>
                                Vezi colectia <span className="icon user-select-none material-icons-outlined pointer">arrow_right_alt</span>
                            </Link>

                            {
                                account?.type == "master" &&
                                <span className="material-icons-outlined pointer text-white" onClick={() => setEditMode(!editMode)}>edit</span>
                            }

                        </>
                }
            </div>
        </div>
    )
}

const Categories = () => {
    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    return (
        <div className="d-flex flex-column justify-content-between mx-auto fitswell">

            <h1 className="title text-start fs-4 text-pink bold">
                <TextInput parameter={"landing_categories_title"} />
            </h1>

            <div className={isMobile ? "d-flex flex-column justify-content-between mx-auto gap-3" : "d-flex flex-row justify-content-center mx-3 gap-4"}>
                {
                    new Array(5).fill(0).map((_, i) =>
                        <Category key={i} index={i + 1} />
                    )
                }
            </div>
        </div>

    )
}

export default Categories;
import {
    REVIEWS_STARTING,
    REVIEWS_SUCCESSFULL,
    REVIEWS_FAILED
} from '../actions/reviews';

import updateObject from "../updateObject";

const initialState = {
    inLoading: false,
    error: "",
    data: []
};

export function reviewsStart(state, action) {
    return updateObject(state, {
        inLoading: true
    });
}

export function reviewsSuccess(state, action) {
    return updateObject(state, {
        inLoading: false,
        data: action.payload
    });
}

export function reviewsFailed(state, action) {
    return updateObject(state, {
        inLoading: false,
        error: action.payload
    });
}

export function reviewsReducer(state = initialState, action) {
    switch (action.type) {
        case REVIEWS_STARTING: return reviewsStart(state, action);
        case REVIEWS_SUCCESSFULL: return reviewsSuccess(state, action);
        case REVIEWS_FAILED: return reviewsFailed(state, action);

        default: return state;
    }
}
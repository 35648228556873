import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';

export const Image = ({ className, parameter, src, alt, onClick }) => {
  const page = useSelector(state => state.page);

  const getPageParameter = (parameter) => {
    for (const param of page?.data) {
      if (param.name == parameter) {
        return param.type == "integer" ? Number(param.value) : param.value;
      }
    }

    return null;
  }

  const ref = useRef(null);
  const [loaded, setLoaded] = useState(false);
  const [source, setSource] = useState(null);

  useEffect(() => {
    if (className != undefined && className != null && ref != undefined && ref != null && ref.current != undefined && ref.current != null) {
      if (!loaded) {
        ref.current.className = `image--loading ${className}`;
      }
      else {
        ref.current.className = className;
      }
    }
  }, [loaded]);

  useEffect(() => {
    if (parameter) {
      console.log("parameter", getPageParameter(parameter));
      setSource(getPageParameter(parameter));
    }
    else {
      setSource(src);
    }
  }, [parameter, src]);

  return (
    source && alt && page ?
    <img ref={ref} loading='lazy' onLoad={() => setLoaded(true)} src={source} alt={alt} onClick={(e) => { if (onClick) onClick(e) }} />
    :
    <div></div>
  );
}

export default Image;
import React from 'react'
import Fade from 'react-reveal'
import LazyLoad from 'react-lazyload';

export const MyLazyLoad = ({ children }) => {
  return (
    <LazyLoad offset={100}>
    <Fade>
      {children}
    </Fade>
    </LazyLoad>
  );
}

export default MyLazyLoad;
import axios from "axios";

export const PROMO_STARTING = 'PROMO_STARTING';
export const PROMO_SUCCESSFULL = 'PROMO_SUCCESSFULL';
export const PROMO_FAILED = 'PROMO_FAILED';
export const PROMO_CLEAR = 'PROMO_CLEAR';

export function promoStarting() {
    return {
        type: PROMO_STARTING
    };
}

export function promoSuccessfull(payload) {
    return {
        type: PROMO_SUCCESSFULL,
        payload: payload
    };
}

export function promoFailed() {
    return {
        type: PROMO_FAILED
    };
}

export function promoClear() {
    return {
        type: PROMO_CLEAR
    };
}

export const fetchPromo = (props) => {
    return async (dispatch) => {
        dispatch(promoStarting());

        axios.get(`${process.env.REACT_APP_API_KEY}/promo?code=${props}`)
            .then(res => {
                dispatch(promoSuccessfull(res.data));
            }).catch(err => {
                dispatch(promoFailed());
            });
    }
};

export const fetchPromos = (props) => {
    return async (dispatch) => {
        dispatch(promoStarting());

        axios.get(`${process.env.REACT_APP_API_KEY}/promo`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Token " + localStorage.getItem("token")
                }
            })
            .then(res => {
                dispatch(promoSuccessfull(res.data));
            }).catch(err => {
                dispatch(promoFailed());
            });
    }
};

export const addPromo = (props) => {
    return async (dispatch) => {
        axios.post(`${process.env.REACT_APP_API_KEY}/promo`,
            {
                ...props
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Token " + localStorage.getItem("token")
                }
            })
            .then(res => {
                dispatch(promoStarting());

                axios.get(`${process.env.REACT_APP_API_KEY}/promo`,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            "Authorization": "Token " + localStorage.getItem("token")
                        }
                    })
                    .then(res => {
                        dispatch(promoSuccessfull(res.data));
                    }).catch(err => {
                        dispatch(promoFailed());
                    });
            }).catch(err => { });
    }
};

export const deletePromo = (props) => {
    return async (dispatch) => {
        axios.delete(`${process.env.REACT_APP_API_KEY}/promo`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Token " + localStorage.getItem("token")
                },
                data: {
                    ...props
                }
            })
            .then(res => {
                dispatch(promoStarting());

                axios.get(`${process.env.REACT_APP_API_KEY}/promo`,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            "Authorization": "Token " + localStorage.getItem("token")
                        }
                    })
                    .then(res => {
                        dispatch(promoSuccessfull(res.data));
                    }).catch(err => {
                        dispatch(promoFailed());
                    });
            }).catch(err => { });
    }
};

export const promoClearNow = () => {
    return async (dispatch) => {
        dispatch(promoClear());
    }
}